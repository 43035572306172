<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader
          >{{ $t("ORDERS.TABLE.5") }}: <b>{{ $route.params.id }}</b>
        </CCardHeader>
        <CCardBody :v-show="showcustomerDetails">
          <CDataTable small fixed :items="visibleData" :fields="detailFields">
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CButton color="durianprimary" @click="goBack">{{
            $t("COMMON.BACK_BUTTON")
          }}</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getCustomer } from "@/api/customer.api.js";

export default {
  name: "OrderDetails",
  data() {
    return {
      selected: null,
      showcustomerDetails: false
    };
  },
  methods: {
    getCustomerDetailsByID() {
      getCustomer(this.$route.params.id)
        .then(response => {
          this.selected = response.data.data;
          this.selected["created_at"] = this.formatDate(
            this.selected["created_at"]
          );
          delete this.selected["metadata"];
          delete this.selected["middle_name"];
          delete this.selected["sur_name"];
          delete this.selected["address_line_1"];
          delete this.selected["address_line_2"];
          delete this.selected["updated_at"];
          delete this.selected["region"];
          this.showcustomerDetails = true;
        })
        .catch(e => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
    getLabelKey(key) {
      switch (key) {
        case "id":
          return this.$t("CUSTOMERS.TABLE.1");
        case "given_name":
          return this.$t("CUSTOMERS.TABLE.2");
        case "city":
          return this.$t("CUSTOMERS.TABLE.3");
        case "country":
          return this.$t("CUSTOMERS.TABLE.4");
        case "customer_ref_id":
          return this.$t("CUSTOMERS.TABLE.6");
        case "email":
          return this.$t("CUSTOMERS.TABLE.8");
        case "mobile":
          return this.$t("CUSTOMERS.TABLE.9");
        case "postal_code":
          return this.$t("CUSTOMERS.TABLE.10");
        case "created_at":
          return this.$t("CUSTOMERS.TABLE.5");
        default:
          return key;
      }
    }
  },
  computed: {
    visibleData() {
      const customerDetails = this.selected
        ? Object.entries(this.selected)
        : [["id", "Not found"]];
      return customerDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    detailFields() {
      if (this.visible) {
        return [
          { key: "key", label: this.selected.id, _style: "width:150px" },
          { key: "value", label: "", _style: "width:150px;" }
        ];
      }
    }
  },
  mounted() {
    this.getCustomerDetailsByID();
  }
};
</script>
